import NotFound from "@/features/NotFound/NotFound";
import { useAuth } from "@/hooks/auth";

function Custom404() {
  const auth = useAuth();

  return auth.user ? <NotFound dashboard /> : <NotFound />;
}

export default Custom404;
