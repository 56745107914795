import { A, Typography } from "@fonoa/ui-components/typography";
import Image from "next/image";
import { FormattedMessage } from "react-intl";

import SidebarLayout from "@/components/Layouts/SidebarLayout";
import { useImages } from "@/components/Utils/Images";

function Dashboard404() {
  const Images = useImages();
  const returnToDashboard = (
    <A className="text-primaryBlue500" href="/" underline>
      <FormattedMessage defaultMessage="return to the Dashboard" id="mL1u/d" />
    </A>
  );

  const dropUsALine = (
    <A className="text-primaryBlue500" href="https://www.fonoa.com/contact-us" underline>
      <FormattedMessage defaultMessage="drop us a line" id="eCs3fg" />
    </A>
  );

  return (
    <SidebarLayout windowTitle="Fonoa Dashboard" withHeaderBar={false}>
      <div className="mt-40 flex flex-col items-center text-center">
        <Image
          src={Images.notFoundPage.src}
          alt={Images.notFoundPage.alt}
          width={Images.notFoundPage.width}
          height={Images.notFoundPage.height}
          className="h-auto max-w-full"
        />
        <div className="min-w-84">
          <Typography
            component="h3"
            color="text-blueGray600"
            fontWeight="font-medium"
            fontSize="text-lg"
            spacing={{ mx: "auto", mt: 10 }}
          >
            <FormattedMessage defaultMessage="Sorry, we couldn’t find that page. :(" id="56p5Ui" />
          </Typography>
        </div>
        <div className="max-w-80">
          <Typography
            spacing={{ mx: "auto", mt: 4 }}
            color="text-blueGray600"
            fontWeight="font-light"
          >
            <FormattedMessage
              defaultMessage="Our API is reliable but mishaps do happen.
            You can {returnToDashboard},
            or {dropUsALine} if you can’t find what you’re looking for."
              id="3PJ5bp"
              values={{ returnToDashboard, dropUsALine }}
            />
          </Typography>
        </div>
      </div>
    </SidebarLayout>
  );
}

export default Dashboard404;
