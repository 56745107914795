import { Button } from "@fonoa/ui-components/button";
import { ContactSupportIcon } from "@fonoa/ui-components/icons";
import { A, Typography } from "@fonoa/ui-components/typography";
import Image from "next/image";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

import { useImages } from "@/components/Utils/Images";

function Default404() {
  const router = useRouter();
  const Images = useImages();

  return (
    <div className="mt-40 flex flex-col items-center text-center">
      <Image
        src={Images.notFoundPage.src}
        alt={Images.notFoundPage.alt}
        width={Images.notFoundPage.width}
        height={Images.notFoundPage.height}
        className="h-auto max-w-full"
      />
      <div className="max-w-84">
        <Typography
          component="h3"
          color="text-blueGray600"
          spacing={{ mx: "auto", mt: 10 }}
          fontWeight="font-medium"
          fontSize="text-lg"
        >
          <FormattedMessage defaultMessage="Sorry, we couldn’t find that page. :(" id="56p5Ui" />
        </Typography>
      </div>
      <Button spacing={{ mx: "auto", mt: 8 }} onClick={() => router.back()}>
        <FormattedMessage defaultMessage="Take me back" id="L9M2rY" />
      </Button>
      <A
        className="mt-5 flex items-center justify-center text-sm text-primaryBlue500"
        href="https://www.fonoa.com/contact-us"
      >
        <ContactSupportIcon className="mr-2" />
        <FormattedMessage defaultMessage="Contact Us" id="hZOGLS" />
      </A>
    </div>
  );
}

export default Default404;
