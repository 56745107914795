import Dashboard404 from "./components/Dashboard404";
import Default404 from "./components/Default404";

interface NotFoundProps {
  dashboard?: boolean;
}

function NotFound({ dashboard = false }: NotFoundProps) {
  return dashboard ? <Dashboard404 /> : <Default404 />;
}

export default NotFound;
